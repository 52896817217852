import { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton } from '../../../Core/components/IconButton/IconButton';
import './SRSFileManagement.scss';
import { Modal } from '../../../Core/components/Modal/Modal';
import { useSectionMenu } from '../../Explorer/useSectionMenuItems';
import { Icon } from '../../../Core/components/Icon/Icon';
import { motion } from 'framer-motion';
import { DragEvent } from 'react';
import { SRSBodySelector } from './body/SRSBodySelector';
import { useSelectedPath } from './SRS.state';
import {
	apiUtil,
	useGetSRSFileTreeQuery,
	useLazyGetSRSUploadLinkQuery,
} from '../../../Core/Api';
import { useDispatch } from 'react-redux';
import { FileTree } from '../../../SharedTypes/API/Explorer';
import {
	addUpload,
	errorUpload,
	removeUpload,
	updateUpload,
} from './body/UploadSRS.state';
import { isDefined } from '../../../Core/utils/Logic';
import { useLocation } from 'react-router-dom';

export const SRSFileManagement = () => {
	const [isSRSFileManagementOpen, setIsSRSFileManagementOpen] = useState(false);
	const [isUploadAreaOpen, setIsUploadAreaOpen] = useState(false);
	const [dragOverUploadArea, setDragOverUploadArea] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.items && e.dataTransfer.items[0].kind === 'file') {
			setDragOverUploadArea(true);
		}
	};

	const handleDragLeave = (e: {
		preventDefault: () => void;
		stopPropagation: () => void;
	}) => {
		e.preventDefault();
		e.stopPropagation();
		setDragOverUploadArea(false);
	};

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.items && e.dataTransfer.items[0].kind === 'file') {
			setDragOverUploadArea(true);
		}
	};

	const handleDrop = (e: {
		preventDefault: () => void;
		stopPropagation: () => void;
		dataTransfer: { files: any };
	}) => {
		e.preventDefault();
		e.stopPropagation();

		if (dragOverUploadArea) {
			// Instantly close the uploadArea when the drop event is triggered
			setIsUploadAreaOpen(false);
		}

		setDragOverUploadArea(false);
		// Process files here
		const files = e.dataTransfer.files;

		handleFileSelection({ target: { files } });
	};

	function handleModalToggle() {
		setIsSRSFileManagementOpen(!isSRSFileManagementOpen);
		setIsUploadAreaOpen(false);
	}

	function handleUploadAreaToggle() {
		setIsUploadAreaOpen(!isUploadAreaOpen);
	}

	const [getUploadLink] = useLazyGetSRSUploadLinkQuery();

	const selectedPath = useSelectedPath();

	const location = useLocation();

	const getSectionId = () => {
		// Split the path by '/' and filter out the ones that can be left over if we end with a '/'
		const splitPath = location.pathname.split('/').filter((path) => path);

		const [, , moduleName, maybeSectionId] = splitPath;

		if (moduleName === 'explorer' && isDefined(maybeSectionId)) {
			return maybeSectionId;
		}
	};

	const sectionId = getSectionId()!;

	// No need for loading or error state since it will always be available in this instance if no other error or loading state is triggered.
	const { data } = useGetSRSFileTreeQuery(
		{
			sectionId,
			selectedPath,
		},
		{ skip: !isSRSFileManagementOpen }
	);

	const dispatch = useDispatch();

	const sectionMenu = useSectionMenu();

	interface FileUpload {
		file: File;
		xhr: XMLHttpRequest;
		uploadFilePath: string;
		sectionId: string;
	}

	interface UploadQueueItem {
		file: File;
		filename: string;
		uploadFilePath: string;
		sectionId: string;
	}

	const MAX_CONCURRENT_UPLOADS = 3; // Maximum number of concurrent uploads allowed - More than 1 doesn't with XMLHttpRequest, but it is the only possibility with GCP afaik. Even GCP can't do more than one.
	const [uploadQueue, setUploadQueue] = useState<UploadQueueItem[]>([]);
	const [activeUploads, setActiveUploads] = useState<FileUpload[]>([]);

	const finishUpload = useCallback((file: File) => {
		setActiveUploads((currentUploads) =>
			currentUploads.filter((upload) => upload.file !== file)
		);
	}, []);

	const startUpload = useCallback(
		(item: UploadQueueItem) => {
			const xhr = new XMLHttpRequest();

			getUploadLink({
				sectionId: item.sectionId,
				selectedPath: item.uploadFilePath,
			}).then((response) => {
				if (response.data) {
					const uploadLink = response.data.uploadLink;
					xhr.open('PUT', uploadLink, true);
					xhr.setRequestHeader('Content-Type', 'application/octet-stream');

					xhr.upload.addEventListener('progress', (event) => {
						if (event.lengthComputable) {
							const percentDone = Math.round(
								(event.loaded / event.total) * 100
							);

							dispatch(
								updateUpload({
									path: item.uploadFilePath,
									fileName: item.filename,
									uploadSpeed: '',
									progress: `${percentDone}%`,
									error: false,
									waiting: false,
								})
							);
						}
					});

					xhr.onload = () => {
						if (xhr.status === 200) {
							finishUpload(item.file);
							dispatch(apiUtil.invalidateTags(['SRS']));
							dispatch(removeUpload(item.uploadFilePath));
						} else {
							finishUpload(item.file);
							dispatch(errorUpload(item.uploadFilePath));
						}
					};

					xhr.onerror = () => {
						finishUpload(item.file);
						dispatch(errorUpload(item.uploadFilePath));
					};

					xhr.send(item.file);
					setActiveUploads((prevUploads) => [...prevUploads, { ...item, xhr }]);
				}
			});
		},
		[dispatch, finishUpload, getUploadLink]
	);

	const enqueueUpload = (
		file: File,
		filename: string,
		uploadFilePath: string,
		sectionId: string
	) => {
		dispatch(
			addUpload({
				path: uploadFilePath,
				fileName: filename,
				uploadSpeed: '0',
				progress: '0%',
				error: false,
				waiting: true,
			})
		);

		const newItem = { file, filename, uploadFilePath, sectionId };
		setUploadQueue((currentQueue) => [...currentQueue, newItem]);
		processQueue();
	};

	const processQueue = useCallback(() => {
		if (
			activeUploads.length < MAX_CONCURRENT_UPLOADS &&
			uploadQueue.length > 0
		) {
			const nextItem = uploadQueue.shift(); // Remove the first item from the queue
			if (nextItem) {
				startUpload(nextItem);
				setUploadQueue(uploadQueue); // Update the queue state
			}
		}
	}, [activeUploads.length, startUpload, uploadQueue]);

	useEffect(() => {
		processQueue();
	}, [processQueue]);

	const handleFileSelection = (e: { target: { files: FileList | null } }) => {
		const files = e.target.files;
		if (!files) return;

		fileInputRef.current?.removeAttribute('webkitdirectory');

		Array.from(files).forEach((file) => {
			const existingFiles = data?.tree || [];
			const filename = generateUniqueFilename(file.name, existingFiles);
			let uploadFilePath = `${selectedPath}/${filename}`;

			if (file.webkitRelativePath) {
				uploadFilePath = `${selectedPath}/${file.webkitRelativePath}`;
			}

			enqueueUpload(file, filename, uploadFilePath, sectionId);
		});
	};

	const isSRSFolderPositionAtRevisionRoot = useSelectedPath() === '';

	const handleUploadClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current?.removeAttribute('webkitdirectory');
			fileInputRef.current.click();
		}
	};

	// Close the upload area if the folder is at the revision root
	useEffect(() => {
		if (isSRSFolderPositionAtRevisionRoot) {
			setIsUploadAreaOpen(false);
		}
	}, [isSRSFolderPositionAtRevisionRoot]);

	return (
		<>
			<div className="ManageSRSButton">
				<IconButton
					icon="Folder"
					theme="blue-white-rounded"
					iconSize={18}
					onClick={handleModalToggle}
				>
					Manage SRS
				</IconButton>
			</div>

			{isSRSFileManagementOpen && (
				<div
					style={{
						position: 'fixed',
						zIndex: '1000',
						height: '100%',
						width: '100%',
					}}
					onDragEnter={(e) => {
						// if is dragging files preventDefautl and open the upload area
						if (
							e.dataTransfer.items &&
							e.dataTransfer.items[0].kind === 'file' &&
							selectedPath !== ''
						) {
							e.preventDefault();
							setIsUploadAreaOpen(true);
						}
					}}
				>
					<Modal
						headerContent={
							<div className="SRSHeader">
								<div className="SRSHeader__Title">
									<p className="SRSHeader__Title__Main">{'Manage SRS '}</p>
									<p className="SRSHeader__Title__Section">
										{`for ${sectionMenu.currentSelection}`}
									</p>
								</div>
								<div className="SRSHeader__RightContainer">
									<div className="SRSHeader__RightContainer__UploadButton">
										{!isSRSFolderPositionAtRevisionRoot && (
											<IconButton
												icon="Upload"
												iconSize={12}
												alignText="left"
												theme={
													isUploadAreaOpen
														? 'greyed-out-transparent'
														: 'blue-text-transparent'
												}
												onClick={handleUploadAreaToggle}
											>
												{isUploadAreaOpen ? 'Close uploader' : 'Upload'}
											</IconButton>
										)}
									</div>
									<div className="SRSHeader__RightContainer__CloseButton">
										<Icon
											name="CircleX"
											width={24}
											onClick={handleModalToggle}
											color="blue"
										/>
									</div>
								</div>
							</div>
						}
						onClickOutside={handleModalToggle}
					>
						<div className="Body">
							<input
								type="file"
								multiple
								ref={fileInputRef}
								style={{ display: 'none' }}
								onChange={handleFileSelection}
							/>
							<motion.div
								className={`Body__UploadArea${
									dragOverUploadArea ? '-Drag-Over' : ''
								}`}
								initial={false}
								animate={{
									height: isUploadAreaOpen ? '150px' : 0,
									opacity: isUploadAreaOpen ? 1 : 0,
								}}
								transition={{ duration: 0.25 }}
								onDragEnter={handleDragEnter}
								onDragOver={handleDragOver}
								onDragLeave={handleDragLeave}
								onDrop={handleDrop}
								onClick={handleUploadClick}
							>
								{isUploadAreaOpen && (
									// Content of the upload area goes here<
									<div
										className={`Body__UploadArea__Content${
											dragOverUploadArea ? '-Drag-Over' : ''
										}`}
									>
										<Icon
											className={`Body__UploadArea__Content__Icon`}
											name="Upload"
											width={28}
										/>
										<div
											className={`Body__UploadArea__Content__Text${
												dragOverUploadArea ? '-Drag-Over' : ''
											}`}
										>
											Drag and drop, or click files to upload.
											<p
												className={`Body__UploadArea__Content__Text__Folder`}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													fileInputRef.current?.setAttribute(
														'webkitdirectory',
														'true'
													);
													fileInputRef.current?.click();
												}}
											>
												Click here to select a folder structure.
											</p>
										</div>
									</div>
								)}
							</motion.div>
							<SRSBodySelector />
						</div>
					</Modal>
				</div>
			)}
		</>
	);
};

const generateUniqueFilename = (
	originalFilename: string,
	existingFiles: FileTree[]
): string => {
	let filename = originalFilename;
	const nameWithoutExt = filename.replace(/\.[^/.]+$/, '');
	const extension = filename.split('.').pop() || ''; // Default to empty string if no extension

	// Check if the original filename is already in use
	if (existingFiles.some((f) => f.name === filename)) {
		let counter = 1; // Start counting from 1

		// Generate new filename with counter until a unique one is found
		while (filenameExists(existingFiles, nameWithoutExt, extension, counter)) {
			counter++;
		}

		// Update filename with the unique counter
		filename = `${nameWithoutExt}(${counter}).${extension}`;
	}

	return filename;
};

const filenameExists = (
	existingFiles: FileTree[],
	nameWithoutExt: string,
	extension: string,
	counter: number
): boolean => {
	return existingFiles.some(
		(f) => f.name === `${nameWithoutExt}(${counter}).${extension}`
	);
};

// Extend the existing attributes with the non-standard `webkitdirectory` to allow for directory uploads
declare module 'react' {
	interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
		// Extend the existing attributes with the non-standard `webkitdirectory`
		webkitdirectory?: string;
	}
}
