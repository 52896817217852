import { useState, useEffect } from 'react';

// Interface defining the props expected by the Delay component.
interface DelayProps {
	children: React.ReactNode; // The content to be displayed after a delay. This can be any React node.
	waitBeforeShow?: number; // Optional. The delay in milliseconds before the children are shown. Defaults to 200ms.
}

/**
 * Delay Component
 *
 * This component is designed to delay the rendering of its children by a specified amount of time.
 * It can be useful for adding delay effects to the UI, such as displaying a loading indicator before
 * showing content, or simply deferring the visibility of components.
 *
 * Props:
 * - `children` (React.ReactNode): The React nodes (elements, components, etc.) that this component will conditionally render after the specified delay.
 * - `waitBeforeShow` (number, optional): The duration in milliseconds to wait before rendering the `children`. If not specified, defaults to 200 milliseconds.
 *
 * Usage:
 * Wrap the content that you wish to delay inside the <Delay> component, optionally specifying the `waitBeforeShow` prop to control the delay duration.
 *
 * Example:
 * ```
 * <Delay waitBeforeShow={1000}>
 *   <div>Content to show after a 1 second delay</div>
 * </Delay>
 * ```
 *
 * Note:
 * The delay is implemented using `setTimeout` within a `useEffect` hook. To prevent memory leaks and ensure proper cleanup,
 * a cleanup function is returned from the `useEffect` hook that clears the timeout when the component unmounts or if the `waitBeforeShow` prop changes.
 */
export const Delay = ({ children, waitBeforeShow = 200 }: DelayProps) => {
	const [isShown, setIsShown] = useState(false); // State to track whether the children should be shown

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShown(true); // Set isShown to true after the specified delay, triggering a re-render to display the children.
		}, waitBeforeShow);

		return () => clearTimeout(timer); // Cleanup function to clear the timeout.
	}, [waitBeforeShow]); // Effect dependencies: Re-run the effect if `waitBeforeShow` changes.

	// Render the children if isShown is true, otherwise render nothing.
	return isShown ? <>{children}</> : null;
};
