import { useDispatch } from 'react-redux';
import {
	useGetSRSRevisionsQuery,
	useLazyGetSRSFolderDescriptionQuery,
} from '../../../../Core/Api';
import { Icon } from '../../../../Core/components/Icon/Icon';
import './SRSRevisionBody.scss';
import { setSelectedPath, useNodeCurrentlyEdited } from '../SRS.state';
import { SRSAddRevision } from './Add/SRSAddRevision';
import { SRSAdd } from './Add/SRSAdd';
import { createRef, useState } from 'react';
import { LoadingBlock } from '../../../../Core/components/LoadingBlock/LoadingBlock';
import { Delay } from '../../../../Core/components/Delay/Delay';
import { ToolTip } from '../../../../Core/components/ToolTip/ToolTip';
import { DescriptionTip } from './Tip/DescriptionTip';
import { SRSMenuControls } from './SRSMenuControls';
import { SRSEdit } from './Edit/SRSEdit';

interface SRSRevisionBodyProps {
	sectionId: string;
	selectedPath: string;
}

export const SRSRevisionBody = ({
	sectionId,
	selectedPath,
}: SRSRevisionBodyProps) => {
	const { isError, isLoading, data } = useGetSRSRevisionsQuery({
		sectionId,
	});

	const [isAddRevisionOpen, setIsAddRevisionOpen] = useState(false);

	const dispatch = useDispatch();

	const [getSRSFolderDescription] = useLazyGetSRSFolderDescriptionQuery();
	const [descriptionFolderData, setDescriptionFolderData] =
		useState<string>('');

	let requestCounter = 0; // Counter to keep track of the most recent request

	function handleMouseOverIcon(name: string) {
		const currentRequestNumber = ++requestCounter;

		const selectedFilePath = selectedPath.concat(
			name.split('-!-')[0],
			'/',
			name
		);
		getSRSFolderDescription({
			sectionId,
			selectedPath: selectedFilePath,
		}).then((res) => {
			if (currentRequestNumber === requestCounter) {
				setDescriptionFolderData(
					res.data?.description ?? 'No description available'
				);
			}
		});
	}

	const handleLeaveIcon = () => {
		setDescriptionFolderData('');
	};

	// State to track the currently open menu's identifier
	const [openMenu, setOpenMenu] = useState<string | null>(null);

	// Handler to toggle the menu open/close
	const handleMenuToggle = (nodeName: string) => {
		setOpenMenu(openMenu === nodeName ? null : nodeName);
	};

	const nodeCurrentlyEdited = useNodeCurrentlyEdited();

	return (
		<div className="SRSRevisionBody">
			{isLoading && (
				<div className="SRSRevisionBody__Loading">
					{/* We use a delay here to only show loading when more than 200ms has gone by */}
					<Delay>
						<LoadingBlock
							iconSize={75}
							color="#429dda"
							backgroundColor="transparent"
						/>
					</Delay>
				</div>
			)}
			{isError && <div>Error</div>}
			<div className="SRSRevisionBody__Revisions">
				{data &&
					data.revisions.map((node) => {
						const hoverRef = createRef<HTMLDivElement>();
						return (
							<div
								key={node.name}
								className="SRSRevisionBody__Revisions__Container"
							>
								<div
									className="SRSRevisionBody__Revisions__Container__Entry"
									onClick={() => {
										dispatch(setSelectedPath(node.name));
									}}
								>
									<div className="SRSRevisionBody__Revisions__Container__Entry__Name">
										{node.name}
									</div>
									<div className="SRSRevisionBody__Revisions__Container__Entry__Controls">
										<div
											className="SRSRevisionBody__Revisions__Container__Entry__Controls__Menu"
											onClick={(e) => {
												e.stopPropagation();
												handleMenuToggle(node.name);
											}}
										>
											<Icon
												className="SRSRevisionBody__Revisions__Container__Entry__Controls__Menu__ThreeDotsH"
												name="ThreeDotsH"
												width={18}
											/>
											<SRSMenuControls
												isOpen={openMenu === node.name}
												selectedNode={
													node.isFolder ? node.name.concat('/') : node.name
												}
												selectedPath={selectedPath}
												type={node.isFolder ? 'folder' : 'file'}
												sectionId={sectionId}
												onClickOutside={() => {
													setOpenMenu(null);
												}}
											/>
										</div>
										<div>
											<Icon
												ref={hoverRef}
												className="SRSRevisionBody__Revisions__Container__Entry__Controls__Info"
												name="Info"
												width={18}
												onMouseEnter={() => {
													handleLeaveIcon(); // Clear the description when hovering over a new icon
													handleMouseOverIcon(node.name);
												}}
												onMouseLeave={handleLeaveIcon}
												onClick={(e) => {
													e.stopPropagation();
													handleMouseOverIcon(node.name);
												}}
											/>
											<ToolTip
												tip={
													!descriptionFolderData ? (
														<LoadingBlock
															iconSize={75}
															color="#429dda"
															backgroundColor="transparent"
														/>
													) : (
														<DescriptionTip
															description={descriptionFolderData}
														/>
													)
												}
												elementRef={hoverRef}
											>
												{undefined}
											</ToolTip>
										</div>
										<Icon
											className="SRSRevisionBody__Revisions__Container__Entry__Controls__CaretRight"
											name="CaretRight"
											width={8}
										/>
									</div>
								</div>
								{nodeCurrentlyEdited === node.name && !isError && (
									<SRSEdit
										sectionId={sectionId}
										selectedPath={selectedPath}
										isFolder={node.isFolder}
										selectedNode={node.name}
										title={node.name}
									/>
								)}
							</div>
						);
					})}
			</div>
			{!isError &&
				!isLoading &&
				(isAddRevisionOpen ? (
					<div className="SRSRevisionBody__Add">
						<SRSAdd
							sectionId={sectionId}
							onCancel={() => setIsAddRevisionOpen(false)}
							addType="revision"
						/>
					</div>
				) : (
					<div className="SRSRevisionBody__AddButton">
						<SRSAddRevision
							onClick={() => {
								setIsAddRevisionOpen(true);
							}}
						/>
					</div>
				))}
		</div>
	);
};
