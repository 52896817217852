import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/redux/store';
import { useAppSelector } from '../../../Core/redux/useAppSelector';

/**
 * SRS state slice
 * Handles the state of the SRS file management
 * - selectedPath: The path of the selected file. Used to keep track of location in the SRS file structure
 * - isSRSFolderPositionAtRevisionRoot: A boolean that indicates if the SRS folder is at the revision root
 * - nodeCurrentlyEdited: The path of the node that is currently being edited
 */

const initialState = {
	isSRSFolderPositionAtRevisionRoot: true,
	selectedPath: '',
	nodeCurrentlyEdited: '',
};

export const srs = createSlice({
	name: 'srs',
	initialState,
	reducers: {
		setIsSRSFolderPositionAtRevisionRoot: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isSRSFolderPositionAtRevisionRoot = payload;
		},
		setSelectedPath: (state, { payload }: PayloadAction<string>) => {
			state.selectedPath = payload;
		},
		setNodeCurrentlyEdited: (state, { payload }: PayloadAction<string>) => {
			state.nodeCurrentlyEdited = payload;
		},
	},
});

export const {
	setIsSRSFolderPositionAtRevisionRoot,
	setSelectedPath,
	setNodeCurrentlyEdited,
} = srs.actions;

export const useIsSRSFolderPositionAtRevisionRoot = (): boolean =>
	useAppSelector(
		(state: RootState) => state.srs.isSRSFolderPositionAtRevisionRoot
	);

export const useSelectedPath = (): string =>
	useAppSelector((state: RootState) => state.srs.selectedPath);

export const useNodeCurrentlyEdited = (): string =>
	useAppSelector((state: RootState) => state.srs.nodeCurrentlyEdited);
