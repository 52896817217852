import background from './login-background.jpg';
import { Icon } from '../Core/components/Icon/Icon';
import { Button } from '../Core/components/Button/Button';
import './Login.scss';
import { OnboardingCreatePasswordForm } from './OnboardingCreatePasswordForm';

export const OnboardingCreatePassword = () => {
	return (
		<div className="Login">
			<div
				className="Login__BackgroundImage"
				style={{ backgroundImage: `url(${background})` }}
			></div>
			<div className="Login__Logo">
				<Icon name="RambollLogoNegative" width={100} />
			</div>
			<div className="Login__Form">
				<div className="Login__Icon">
					<Icon name="RiamsLogo" width={50} />
				</div>
				<h1 className="Login__Title">Welcome!</h1>
				<div className="Login__Instruction">
					You need to create a password for your new account.
				</div>
				<OnboardingCreatePasswordForm />
				<div className="Login__TOS">
					<Button theme="GreyText">
						<a
							className="Login__Link"
							href={`/assets/${encodeURIComponent(
								'RIAMS - Terms and Conditions for use.pdf'
							)}`}
							target="_blank"
							rel="noreferrer"
						>
							Terms of Use
						</a>
					</Button>
					<Button theme="GreyText">
						<a
							className="Login__Link"
							href={`/assets/${encodeURIComponent(
								'RIAMS - Privacy policy.pdf'
							)}`}
							target="_blank"
							rel="noreferrer"
						>
							Privacy Policy
						</a>
					</Button>
				</div>
			</div>
		</div>
	);
};
