import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	useAdminGetSiteUsersQuery,
	useAdminRemoveSiteUserMutation,
	useAdminResendActivationCodeMutation,
} from '../../Core/Api';
import { ActiveIndicator } from '../../Core/components/ActiveIndicator/ActiveIndicator';
import { List } from '../../Core/components/List/List';
import { ListItem } from '../../Core/components/List/ListItem';
import { ListLoading } from '../../Core/components/List/ListLoading';
import { toastConfig } from '../../Core/configuration/toast';
import { byKeyIncludes } from '../../Core/utils/Filter';
import { User } from '../../SharedTypes/API/Admin';
import { AdminLayout } from './AdminLayout';
import { AdminRemoveUserModal } from './AdminModals';

export const AdminSiteUsers = () => {
	const [userToRemove, setUserToRemove] = useState<User | null>(null);
	const [filter, setFilter] = useState('');
	const navigate = useNavigate();
	const { selectedSiteId } = useParams<{ selectedSiteId: string }>();
	const [removeSiteUser] = useAdminRemoveSiteUserMutation();
	const [resendActivationCode] = useAdminResendActivationCodeMutation();

	const {
		data: usersOnSiteData,
		isLoading,
		isSuccess,
	} = useAdminGetSiteUsersQuery({
		siteId: selectedSiteId!,
	});

	const memoizedUsers = useMemo(() => {
		if (usersOnSiteData && usersOnSiteData.users) {
			return usersOnSiteData.users.filter(byKeyIncludes('name', filter));
		}
		return [];
	}, [filter, usersOnSiteData]);

	console.log(memoizedUsers);

	const handleSelectedItem = (userId: string) => (action: string) => {
		if (action === 'edit') {
			navigate(`${userId}`);
		}

		if (action === 'resend') {
			resendActivationCode({ userId: userId }).then(() => {
				toast('Confirmation code resent', toastConfig.success);
			});

			return;
		}

		if (action === 'delete') {
			const userInQuestion = memoizedUsers.find((user) => user.id === userId);
			if (!userInQuestion) {
				// This is mostly here to keep typescript happy
				// Show some kind of error message maybe?
				return;
			}
			setUserToRemove(userInQuestion);
		}
	};

	const handleModalClose = (doAction: boolean) => {
		// If true, then remove the user
		if (!doAction) {
			setUserToRemove(null);
			return;
		}
		// Otherwise, well, close the modal and do nothing
		removeSiteUser({
			userId: userToRemove!.id,
			siteId: selectedSiteId!,
		}).then((response) => {
			if ('error' in response) {
				// Handle error
				// @ts-ignore
				toast(response.error.data.message, toastConfig.error);
			}

			setUserToRemove(null);
		});
	};

	const handleGoToNewUser = () => {
		navigate('new');
	};

	const handleSetFilter = (searchTerm: string) => setFilter(searchTerm);

	const getPopMenuItems = (userIsActive: boolean) => {
		const defaultItems = [
			{
				key: 'edit',
				label: 'Edit',
			},
			{
				key: 'delete',
				label: 'Remove',
			},
		];
		if (!userIsActive) {
			defaultItems.push({
				key: 'resend',
				label: 'Resend activation code',
			});
		}

		return defaultItems;
	};

	return (
		<AdminLayout
			title="Users"
			searchHandler={handleSetFilter}
			searchTerm={filter}
		>
			{userToRemove && (
				<AdminRemoveUserModal
					respond={handleModalClose}
					name={userToRemove.name}
				/>
			)}
			<List handleOnAdd={handleGoToNewUser} buttonLabel="Invite user">
				{isLoading && (
					<ListItem templateColumns="1fr">
						<ListLoading text="Loading users" />
					</ListItem>
				)}
				{!isLoading && memoizedUsers.length === 0 && (
					<ListItem templateColumns="1fr">
						<ListLoading text="No users on site" />
					</ListItem>
				)}
				{isSuccess &&
					memoizedUsers.map((user) => (
						<ListItem
							key={user.id}
							templateColumns="1.5fr 1.5fr 1fr 1fr 1fr"
							popMenuItems={getPopMenuItems(user.active)}
							handleMenuItemSelected={handleSelectedItem(user.id)}
						>
							<p>{user.name}</p>
							<p>{user.email}</p>
							<p>{user.accessLevel}</p>
							<ActiveIndicator active={user.active} />
						</ListItem>
					))}
			</List>
		</AdminLayout>
	);
};
