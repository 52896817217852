import { useEffect, useRef, useState } from 'react';
import { Textbox } from '../../../../../Core/components/Textbox/Textbox';
import './SRSAdd.scss';
import { Button } from '../../../../../Core/components/Button/Button';
import { AnimatePresence, motion } from 'framer-motion';
import {
	useAddSRSFolderMutation,
	useAddSRSRevisionMutation,
} from '../../../../../Core/Api';
import { useSelectedPath } from '../../SRS.state';
import classNames from 'classnames';
import { Icon } from '../../../../../Core/components/Icon/Icon';

interface SRSAddProps {
	sectionId: string;
	addType: 'folder' | 'revision';
	onCancel: () => void;
}

export const SRSAdd = ({ addType, onCancel, sectionId }: SRSAddProps) => {
	// This is not a title, but a header title! - Used for the title of the add folder/revision modal in the SRSBodyFileComponent
	const headerTitle = 'Add '.concat(addType);

	const [titleTextBoxValue, setTitleTextBoxValue] = useState('');

	const selectedPath = useSelectedPath();

	const handleTitleTextBoxValue = (value: string) => {
		//do not allow special symbols other than -
		//GCP Buckets are very picky about what characters are allowed
		// value = value.replace(/[^a-zA-Z0-9-]/g, '');

		// ensure max length of 24 characters
		if (value.length <= 24) {
			setTitleTextBoxValue(value.trimStart());
		}
	};

	const [descriptionTextBoxValue, setDescriptionTextBoxValue] = useState('');

	const handleDescriptionTextBoxValue = (value: string) => {
		//max length of 200 characters
		if (value.length <= 200) {
			setDescriptionTextBoxValue(value);
		}
	};

	const [addRevision] = useAddSRSRevisionMutation();
	const [addFolder] = useAddSRSFolderMutation();

	function handleAddRevision(
		sectionId: string,
		revisionName: string,
		revisionDescription: string
	) {
		addRevision({
			sectionId: sectionId,
			revisionName: revisionName,
			revisionDescription:
				revisionDescription === '' ? '' : revisionDescription,
		}).then(() => {
			onCancel();
		});
	}

	function handleAddFolder(
		sectionId: string,
		folderPath: string,
		folderDescription: string
	) {
		addFolder({
			sectionId: sectionId,
			folderPath: folderPath,
			folderDescription: folderDescription === '' ? '' : folderDescription,
		}).then(() => {
			onCancel();
		});
	}

	function handleAdd() {
		if (addType === 'revision') {
			handleAddRevision(sectionId, titleTextBoxValue, descriptionTextBoxValue);
		} else {
			handleAddFolder(
				sectionId,
				selectedPath.concat('/').concat(titleTextBoxValue),
				descriptionTextBoxValue
			);
		}
	}

	// Ref for the container to scroll into view
	const containerRef = useRef<HTMLDivElement>(null);

	// useEffect to scroll into view on component mount
	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	const [isLoadingChange, setIsLoadingChange] = useState(false);

	return (
		<AnimatePresence>
			<div className="SRSAdd" ref={containerRef}>
				<motion.p
					initial={{ color: '#009de0' }} // Starting state before the animation
					animate={{ color: '#809199' }} // End state after the animation
					transition={{ duration: 1 }} // Duration of the animation
					className="SRSAdd__Title"
				>
					{headerTitle}
				</motion.p>
				<motion.div
					initial={{ opacity: 0, y: 50 }} // Starting state before the animation
					animate={{ opacity: 1, y: 0 }} // End state after the animation
					transition={{ duration: 0.2 }} // Duration of the animation
				>
					<div className="SRSAdd__TextboxContainer">
						<Textbox
							title={'Title'}
							onChange={handleTitleTextBoxValue}
							value={titleTextBoxValue}
						/>
						<Textbox
							title={'Description'}
							onChange={handleDescriptionTextBoxValue}
							value={descriptionTextBoxValue}
							multiline
						/>
					</div>
					<div
						className={classNames('SRSAdd__Buttons', {
							'SRSAdd__Buttons--Loading': isLoadingChange,
						})}
					>
						<Button theme="OutlineBlue" onClick={onCancel}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								handleAdd();
								setIsLoadingChange(true);
							}}
						>
							{isLoadingChange ? (
								<Icon
									name="LoadingSpinner"
									width={15}
									style={{ color: 'Blue' }}
								/>
							) : (
								'Add'
							)}
						</Button>
					</div>
				</motion.div>
			</div>
		</AnimatePresence>
	);
};
