import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import './Modal.scss';

type BaseProps = {
	children: React.ReactNode;
	footerContent?: React.ReactNode;
	width?: string;
	onClickOutside?: () => void;
};

type WithTitle = BaseProps & {
	title: string;
	headerContent?: never; // Explicitly mark as never when title is provided
};

// Header content can be any ReactNode. Intended for more complex header content.
type WithHeaderContent = BaseProps & {
	title?: never; // Explicitly mark as never when headerContent is provided
	headerContent: React.ReactNode;
};

// To ensure that either title or headerContent is provided, but not both.
type Props = WithTitle | WithHeaderContent;

export const Modal = ({
	children,
	title,
	headerContent,
	footerContent,
	width = '600px',
	onClickOutside,
}: Props) => {
	const style = {
		width,
	};

	// Handles onClickOutside event - Intended to be used to close modal and run a callback if needed
	const modalOverlayRef = useRef<HTMLDivElement | null>(null);

	useOnClickOutside(modalOverlayRef, () => {
		const popMenuItem = document.querySelector('.PopMenu__Item');

		if (popMenuItem) {
			return;
		}

		if (onClickOutside) {
			onClickOutside();
		}
	});

	return (
		<>
			<div className="ModalOverlay">
				<div className="Modal" ref={modalOverlayRef} style={style}>
					<div className="Modal__Header">
						{headerContent}
						{title && (
							<div
								className="Modal__Title"
								dangerouslySetInnerHTML={{ __html: title }}
							></div>
						)}
					</div>
					<div className="Modal__Body">{children}</div>
					{footerContent && (
						<div className="Modal__Footer">{footerContent}</div>
					)}
				</div>
			</div>
		</>
	);
};
