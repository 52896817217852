import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../Core/redux/store';
import { useAppSelector } from '../../../../Core/redux/useAppSelector';

// Upload data structure. This is the data that will be stored in the state.
// So the reason for using an object with a key lookup here instead of an array, is that we want to be able to update the state of a specific upload by its path.
// Traversing an array to find the upload to update would be inefficient.

interface UploadData {
	path: string;
	fileName: string;
	uploadSpeed: string; // speed in kB/s or MB/s, for instance
	progress: string; // progress as a percentage (0 to 100)
	error: boolean;
	waiting: boolean;
}

interface UploadsState {
	uploads: { [key: string]: UploadData };
}

const initialState: UploadsState = {
	uploads: {},
};

export const uploadSRS = createSlice({
	name: 'uploads',
	initialState,
	reducers: {
		addUpload: (state, action: PayloadAction<UploadData>) => {
			const upload = action.payload;
			state.uploads[upload.path] = upload; // Using the path as the key
		},
		updateUpload: (state, action: PayloadAction<UploadData>) => {
			const upload = action.payload;
			if (state.uploads[upload.path]) {
				state.uploads[upload.path] = upload;
			}
		},
		removeUpload: (state, action: PayloadAction<string>) => {
			// payload is the path
			delete state.uploads[action.payload];
		},
		errorUpload: (state, action: PayloadAction<string>) => {
			// payload is the path
			state.uploads[action.payload].error = true;
		},
	},
});

export const { addUpload, updateUpload, removeUpload, errorUpload } =
	uploadSRS.actions;

export const useSRSUploads = (): { [key: string]: UploadData } =>
	useAppSelector((state: RootState) => state.uploadSRS.uploads);
