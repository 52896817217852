import { IconButton } from '../../../../../Core/components/IconButton/IconButton';

interface SRSAddRevisionProps {
	onClick: () => void;
}

export const SRSAddRevision = ({ onClick }: SRSAddRevisionProps) => {
	return (
		<IconButton
			icon="CirclePlus"
			alignText="left"
			theme="blue-text-transparent"
			iconSize={18}
			onClick={onClick}
		>
			Add revision
		</IconButton>
	);
};
