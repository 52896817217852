import { useDispatch } from 'react-redux';
import { Icon } from '../../../../../Core/components/Icon/Icon';
import './SRSUpload.scss';
import { removeUpload } from '../UploadSRS.state';

interface SRSUploadProps {
	fileName: string;
	progress: string;
	uploadSpeed: string;
	error: boolean;
	waiting: boolean;
	pathKey: string;
}

export const SRSUpload = ({
	fileName,
	progress,
	uploadSpeed,
	error,
	waiting,
	pathKey,
}: SRSUploadProps) => {
	const dispatch = useDispatch();

	let nodeName = fileName;
	const maxNameLength = 35;

	if (fileName.length > maxNameLength) {
		const extensionMatch = fileName.match(/\.[^/.]+$/);
		const extension = extensionMatch ? extensionMatch[0] : '';
		const nameWithoutExtension = extensionMatch
			? fileName.slice(0, -extension.length)
			: fileName;

		if (nameWithoutExtension.length > maxNameLength - extension.length) {
			nodeName = `${nameWithoutExtension.substring(
				0,
				maxNameLength - extension.length - 3
			)}..${extension}`;
		} else {
			nodeName = nameWithoutExtension + extension;
		}
	}

	return (
		<div className="SRSUpload">
			<div className="SRSUpload__fileName">{nodeName}</div>
			{/* progress bar*/}
			<div className="SRSUpload__progressContainer">
				<div
					className={`SRSUpload__progressContainer__uploadText${
						error ? '--error' : ''
					}`}
				>
					{error ? 'Upload failed' : waiting ? 'Awaiting Upload' : 'Uploading'}
				</div>
				{!waiting && (
					<>
						<div className="SRSUpload__progressContainer__progressBarContainer">
							<div
								className={`SRSUpload__progressContainer__progressBarContainer__progressBar${
									error ? '--error' : ''
								}`}
								style={{ width: progress }}
							/>
						</div>
						{error ? (
							<Icon
								name="X"
								width={10}
								className="SRSUpload__progressContainer__errorDismiss"
								onClick={() => {
									dispatch(removeUpload(pathKey));
								}}
							/>
						) : (
							<div className="SRSUpload__progressContainer__progress">
								{progress}
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
