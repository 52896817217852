import { AnimatePresence, motion } from 'framer-motion';
import { DropdownOption } from '../../../../Core/components/DropdownMenu/DropdownMenu';
import './SRSMenuControls.scss';
import { useDeleteSRSNodeMutation } from '../../../../Core/Api';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { useDispatch } from 'react-redux';
import { setNodeCurrentlyEdited } from '../SRS.state';

interface MenuControlsProps {
	isOpen: boolean;
	type: 'folder' | 'file' | 'revision';
	selectedPath: string;
	selectedNode: string;
	sectionId: string;
	onClickOutside?: () => void;
}

export const SRSMenuControls = ({
	isOpen,
	type,
	sectionId,
	selectedNode,
	selectedPath,
	onClickOutside,
}: MenuControlsProps) => {
	// Dropdown animation variants
	const dropdownVariants = {
		hidden: {
			opacity: 0,
			scale: 0.95,
			transition: {
				duration: 0.15,
			},
		},
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				duration: 0.15,
			},
		},
	};

	const menuRef = useRef<HTMLDivElement>(null); // Create a ref for the menu
	const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
		useState(false);

	useOnClickOutside(menuRef, (e) => {
		e.stopPropagation();
		onClickOutside && onClickOutside();
	}); // Hook to handle click outside

	const fileOptions: DropdownOption[] = [
		{ value: 'edit', label: 'Edit' },
		{ value: 'delete', label: 'Delete' },
	];
	const folderOptions = fileOptions;
	const revisionOptions = fileOptions;

	function getOptions(type: 'file' | 'folder' | 'revision') {
		switch (type) {
			case 'file':
				return fileOptions;
			case 'folder':
				return folderOptions;
			case 'revision':
				return revisionOptions;
			default:
				throw new Error('Invalid type');
		}
	}

	const options = getOptions(type);

	const [deleteNode] = useDeleteSRSNodeMutation();
	const dispatch = useDispatch();

	const titleType =
		selectedPath === ''
			? 'Revision'
			: type.charAt(0).toUpperCase() + type.slice(1);

	function handleOptionClick(value: string) {
		switch (value) {
			case 'delete':
				setIsDeleteConfirmationOpen(true);
				break;
			case 'edit':
				dispatch(setNodeCurrentlyEdited(selectedNode.split('/')[0]));
				break;
			default:
				throw new Error('Invalid option');
		}
	}

	const handleDelete = () => {
		deleteNode({
			sectionId,
			nodePath:
				selectedPath.length > 0
					? selectedPath.concat('/', selectedNode)
					: selectedNode,
		}).then(() => {
			dispatch(setNodeCurrentlyEdited('')); // To avoid stale state
			setIsDeleteConfirmationOpen(false);
		});
	};

	const modalRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(modalRef, (e) => {
		e.stopPropagation();
		setIsDeleteConfirmationOpen(false);
	});

	return (
		<div className="SRSMenuControls" key={`${selectedNode}-menu`}>
			{/* AnimatePresence allows components to animate out when they're removed from the component tree */}
			<AnimatePresence>
				{isOpen && (
					<motion.div
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={dropdownVariants}
						className="SRSMenuControls__Menu"
						ref={menuRef}
					>
						{options.map((option) => (
							<div
								key={`${option.value}-${selectedNode}`}
								style={{ padding: '10px', cursor: 'pointer' }}
								className="SRSMenuControls__Menu__Item"
								onClick={() => {
									handleOptionClick(option.value);
								}}
							>
								{option.label}
							</div>
						))}
					</motion.div>
				)}
			</AnimatePresence>
			{isDeleteConfirmationOpen && (
				<div className="SRSMenuControls__DeleteConfirmation">
					<div
						className="SRSMenuControls__DeleteConfirmation__Content"
						ref={modalRef}
					>
						<div className="SRSMenuControls__DeleteConfirmation__Content__Title">
							Delete this {titleType}?
						</div>
						<div className="SRSMenuControls__DeleteConfirmation__Content__Text">
							Are you sure you want to delete this {type}? This action cannot be
							undone.
						</div>
						<div className="SRSMenuControls__DeleteConfirmation__Content__Buttons">
							<button
								className="SRSMenuControls__DeleteConfirmation__Content__Buttons__Cancel"
								onClick={() => setIsDeleteConfirmationOpen(false)}
							>
								Cancel
							</button>
							<button
								className="SRSMenuControls__DeleteConfirmation__Content__Buttons__Delete"
								onClick={(e) => {
									e.stopPropagation();
									handleDelete();
									setIsDeleteConfirmationOpen(false);
								}}
							>
								Yes, delete it
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
