import { useDispatch } from 'react-redux';
import {
	useGetSRSFileTreeQuery,
	useLazyGetSRSDownloadLinkQuery,
	useLazyGetSRSFileMetaDataQuery,
	useLazyGetSRSFolderDescriptionQuery,
} from '../../../../Core/Api';
import { Icon } from '../../../../Core/components/Icon/Icon';
import { SRSAddFolder } from './Add/SRSAddFolder';

import './SRSFileStructureBody.scss';
import { setSelectedPath, useNodeCurrentlyEdited } from '../SRS.state';
import { LoadingBlock } from '../../../../Core/components/LoadingBlock/LoadingBlock';
import { Delay } from '../../../../Core/components/Delay/Delay';
import { createRef, useEffect, useState, useTransition } from 'react';
import { SRSAdd } from './Add/SRSAdd';
import classNames from 'classnames';
import { SRSMenuControls } from './SRSMenuControls';
import { FileTree, MetaData } from '../../../../SharedTypes/API/Explorer';
import { ToolTip } from '../../../../Core/components/ToolTip/ToolTip';
import { DescriptionTip } from './Tip/DescriptionTip';
import { FileTreeTip } from './Tip/FileTreeTip';
import { SRSEdit } from './Edit/SRSEdit';
import { useSRSUploads } from './UploadSRS.state';
import { SRSUpload } from './Upload/SRSUpload';

interface SRSFileStructureBodyProps {
	sectionId: string;
	selectedPath: string;
}

export const SRSFileStructureBody = ({
	sectionId,
	selectedPath,
}: SRSFileStructureBodyProps) => {
	const { isError, isLoading, data } = useGetSRSFileTreeQuery({
		sectionId,
		selectedPath,
	});

	const [isPending, startTransition] = useTransition();
	const [isAddFolderOpen, setIsAddFolderOpen] = useState(false);

	const dispatch = useDispatch();

	const [getDownloadFile] = useLazyGetSRSDownloadLinkQuery();

	const [getSRSFileMetaData] = useLazyGetSRSFileMetaDataQuery();
	const [metaDataFileData, setMetaDataFileData] = useState<
		MetaData | undefined
	>(undefined);

	const [getSRSFolderDescription] = useLazyGetSRSFolderDescriptionQuery();
	const [descriptionFolderData, setDescriptionFolderData] =
		useState<string>('');

	const [requestCounter, setRequestCounter] = useState(0);
	const fetchTooltipData = (name: string, isFolder: boolean) => {
		const currentRequestNumber = requestCounter + 1;
		setRequestCounter(currentRequestNumber);

		const selectedFilePath = isFolder
			? selectedPath.concat('/', name.split('-!-')[0], '/', name)
			: selectedPath.concat('/', name);

		if (isFolder) {
			getSRSFolderDescription({
				sectionId,
				selectedPath: selectedFilePath,
			}).then((res) => {
				if (currentRequestNumber - 1 === requestCounter) {
					setDescriptionFolderData(
						res.data?.description ?? 'No description available'
					);
				}
			});
		} else {
			getSRSFileMetaData({ sectionId, selectedPath: selectedFilePath }).then(
				(res) => {
					if (currentRequestNumber - 1 === requestCounter) {
						setMetaDataFileData(res.data?.metaData);
					}
				}
			);
		}
	};

	const handleIconInteraction = (
		name: string,
		isFolder: boolean,
		forceFetch: boolean = false
	) => {
		// Prevents unnecessary fetching if data already exists
		if (
			(isFolder && descriptionFolderData) ||
			(!isFolder && metaDataFileData)
		) {
			if (!forceFetch) return; // Skip fetching if data is already present and no forced fetch is requested
		}
		fetchTooltipData(name, isFolder);
	};

	const handleTouchStart = (name: string, isFolder: boolean) => {
		// Force fetch data on touch start to ensure it's always triggered on touch devices
		handleIconInteraction(name, isFolder, true);
	};

	const handleDownloadFile = (
		sectionId: string,
		selectedPath: string,
		selectedFile: string
	) => {
		const selectedFilePath = selectedPath.concat('/', selectedFile);
		getDownloadFile({ sectionId, selectedPath: selectedFilePath }).then(
			(res) => {
				window.open(res.data?.downloadLink, '_blank');
			}
		);
	};

	// State to track the currently open menu's identifier
	const [openMenu, setOpenMenu] = useState<string | null>(null);

	// Handler to toggle the menu open/close
	const handleMenuToggle = (nodeName: string) => {
		setOpenMenu(openMenu === nodeName ? null : nodeName);
	};

	const currentPath = selectedPath.split('/').slice(-1)[0];
	const prevPath = selectedPath.split('/').slice(0, -1);

	const nodeCurrentlyEdited = useNodeCurrentlyEdited();

	const uploads = useSRSUploads();

	const [tree, setTree] = useState<FileTree[] | undefined>(undefined);

	// This useEffects sorts the data.tree to have folders first and then files.
	// It will otherwise follow the order of the data.tree (alphabetical order)
	useEffect(() => {
		if (!data || !data.tree) return;

		startTransition(() => {
			let sortedData = data?.tree.slice();

			sortedData.sort((a, b) => {
				if (a.isFolder === b.isFolder) {
					// Check if both names are numeric
					const isANumeric = !isNaN(Number.parseInt(a.name));
					const isBNumeric = !isNaN(Number.parseInt(b.name));

					if (isANumeric && isBNumeric) {
						// Compare as numbers
						return parseInt(a.name) - parseInt(b.name);
					} else if (isANumeric) {
						return -1; // Always put numbers before strings
					} else if (isBNumeric) {
						return 1; // Always put strings after numbers
					} else {
						// Compare as strings
						return a.name.localeCompare(b.name);
					}
				}
				return a.isFolder ? -1 : 1;
			});

			setTree(sortedData);
		});
	}, [data]);

	const [emptyFoldersWithActiveUploads, setEmptyFoldersWithActiveUploads] =
		useState<FileTree[]>([]);

	// This useEffect adds empty folders for active uploads to the tree
	// Warning: This has a tendency to not work if touched :)
	useEffect(() => {
		// Reset empty folders when the selected path changes
		setEmptyFoldersWithActiveUploads([]);

		if (!tree) return;

		// Create a map of existing folders for quick lookup
		const existingFolderNames = new Set(tree.map((node) => node.name));

		// Track the names of folders that are already added to avoid duplicates
		const addedFolderNames = new Set();

		// Filter and add folders from uploads that are not in the existing tree
		const newEmptyFolders = Object.values(uploads).reduce(
			(acc: FileTree[], upload) => {
				// Get the path components and the last folder name
				const folderName = upload.path.split('/').slice(-2)[0];

				// Ensure the folder belongs to the current path
				const uploadPath = upload.path.split('/').slice(0, -2).join('/');

				if (
					uploadPath === selectedPath &&
					!existingFolderNames.has(folderName) &&
					!addedFolderNames.has(folderName) // Check if the folder is already added
				) {
					addedFolderNames.add(folderName); // Mark the folder as added
					acc.push({
						name: folderName,
						isFolder: true,
						children: [],
						isFolderEmpty: false,
					});
				}
				return acc;
			},
			[]
		);

		// Update state with the new folders
		setEmptyFoldersWithActiveUploads(newEmptyFolders);
	}, [uploads, selectedPath, tree]); // Depend on uploads, selectedPath, and tree

	return (
		<div className="SRSFileStructureBody">
			<div
				className="SRSFileStructureBody__Navigation"
				onClick={() => {
					// Go back one level
					dispatch(
						setSelectedPath(selectedPath.split('/').slice(0, -1).join('/'))
					);
					setIsAddFolderOpen(false);
				}}
			>
				<Icon
					className="SRSFileStructureBody__Navigation__BackButton"
					name={'CaretRight'}
					width={8}
				/>
				<div className="SRSFileStructureBody__Navigation__Path">
					<div className="SRSFileStructureBody__Navigation__Path__prevPath">
						{prevPath.map((path, index) => {
							const totalPaths = prevPath.length;
							let displayPath = path;

							// Determine if special truncation rules apply
							const totalChars = prevPath.reduce(
								(acc, cur) => acc + cur.length,
								0
							);
							const shouldTruncate = totalChars > 30;

							// Truncate path to 10 characters if required
							if (
								shouldTruncate &&
								(index === 0 || index === totalPaths - 1) &&
								path.length > 10
							) {
								displayPath = path.substring(0, 10) + '..';
							}

							if (index === 0 || index === totalPaths - 1) {
								return (
									<div
										className="SRSFileStructureBody__Navigation__Path__prevPath__Content"
										key={path + index}
									>
										<span
											onClick={(e) => {
												e.stopPropagation();
												if (index === 0) {
													dispatch(setSelectedPath(''));
												} else {
													dispatch(
														setSelectedPath(
															selectedPath.split('/').slice(0, -1).join('/')
														)
													);
													setIsAddFolderOpen(false);
												}
											}}
											className={
												index === 0
													? 'SRSFileStructureBody__Navigation__Path__prevPath__Content__FirstElement'
													: ''
											}
										>
											{displayPath}
										</span>
										<span>/</span>
									</div>
								);
							} else if (index === 1 && totalPaths > 2) {
								// Insert ellipsis only once between the first and the last elements
								return (
									<div
										className="SRSFileStructureBody__Navigation__Path__prevPath__Content"
										key="ellipsis"
									>
										<span>...</span>
										<span>/</span>
									</div>
								);
							}
							return null;
						})}
					</div>
					<p className="SRSFileStructureBody__Navigation__Path__currentPath">
						{currentPath}
					</p>
				</div>
			</div>
			{(isLoading || isPending) && (
				<div className="SRSFileStructureBody__Loading">
					{/* We use a delay here to only show loading when more than 200ms has gone by */}
					<Delay>
						<LoadingBlock
							iconSize={75}
							color="#429dda"
							backgroundColor="transparent"
						/>
					</Delay>
				</div>
			)}

			{isError && <div>Error</div>}
			{tree && !isPending && (
				<div>
					{[...tree, ...emptyFoldersWithActiveUploads].map((node, index) => {
						const hoverRef = createRef<HTMLDivElement>();

						// if node.name exceeds 45 charcters truncate it to e.g. file...txt
						let nodeName = node.name;
						const maxNameLength = 45;

						if (node.name.length > maxNameLength) {
							const extensionMatch = node.name.match(/\.[^/.]+$/);
							const extension = extensionMatch ? extensionMatch[0] : '';
							const nameWithoutExtension = extensionMatch
								? node.name.slice(0, -extension.length)
								: node.name;

							if (
								nameWithoutExtension.length >
								maxNameLength - extension.length
							) {
								nodeName = `${nameWithoutExtension.substring(
									0,
									maxNameLength - extension.length - 3
								)}..${extension}`;
							} else {
								nodeName = nameWithoutExtension + extension;
							}
						}

						const nodeRef = createRef<HTMLDivElement>();

						return (
							<div
								style={{ borderBottom: '1px solid #d4e0e6' }}
								key={`${node.name}-${index}`}
							>
								<div
									className={classNames('SRSFileStructureBody__Node', {
										'SRSFileStructureBody__Node--Folder': node.isFolder,
										'SRSFileStructureBody__Node--File': !node.isFolder,
									})}
									onClick={() => {
										if (node.isFolder) {
											dispatch(setSelectedPath(`${selectedPath}/${node.name}`));
											setIsAddFolderOpen(false);
										}
									}}
								>
									<p ref={nodeRef} className="SRSFileStructureBody__Node__Name">
										{nodeName}
									</p>
									<ToolTip
										isEnabled={node.name.length > maxNameLength}
										elementRef={nodeRef}
										tip={node.name}
										children={undefined}
									/>
									<div className="SRSFileStructureBody__Node__Controls">
										<div
											className="SRSFileStructureBody__Node__Controls__Menu"
											onClick={(e) => {
												e.stopPropagation();
												handleMenuToggle(node.name);
											}}
										>
											<Icon
												className="SRSFileStructureBody__Node__Controls__Menu__ThreeDotsH"
												name="ThreeDotsH"
												width={18}
											/>
											<SRSMenuControls
												isOpen={openMenu === node.name}
												selectedNode={
													node.isFolder ? node.name.concat('/') : node.name
												}
												selectedPath={selectedPath}
												type={node.isFolder ? 'folder' : 'file'}
												sectionId={sectionId}
												onClickOutside={() => {
													if (openMenu) {
														setOpenMenu(null);
													}
												}}
											/>
										</div>
										<div>
											<Icon
												ref={hoverRef}
												className="SRSFileStructureBody__Node__Controls__Info"
												name="Info"
												width={18}
												onMouseEnter={() =>
													handleIconInteraction(node.name, node.isFolder, true)
												}
												onTouchStart={(e) => {
													e.stopPropagation();
													handleTouchStart(node.name, node.isFolder);
												}}
												onClick={(e) => {
													e.stopPropagation();
													handleIconInteraction(node.name, node.isFolder, true);
												}}
											/>
											<ToolTip
												tip={
													node.isFolder ? (
														!descriptionFolderData ? (
															<LoadingBlock
																iconSize={75}
																color="#429dda"
																backgroundColor="transparent"
															/>
														) : (
															<DescriptionTip
																description={descriptionFolderData}
															/>
														)
													) : !metaDataFileData ? (
														<LoadingBlock
															iconSize={75}
															color="#429dda"
															backgroundColor="transparent"
														/>
													) : (
														<FileTreeTip metaData={metaDataFileData!} />
													)
												}
												elementRef={hoverRef}
											>
												{undefined}
											</ToolTip>
										</div>
										<div className="SRSFileStructureBody__Node__Controls__ConditionContainer">
											{node.isFolder ? (
												<Icon
													className="SRSFileStructureBody__Node__Controls__CaretRight"
													name="CaretRight"
													width={8}
												/>
											) : (
												<Icon
													className="SRSFileStructureBody__Node__Controls__Download"
													name="Download"
													width={12}
													onClick={() =>
														handleDownloadFile(
															sectionId,
															selectedPath,
															node.name
														)
													}
												/>
											)}
										</div>
									</div>
								</div>
								{nodeCurrentlyEdited === node.name && (
									<SRSEdit
										sectionId={sectionId}
										selectedPath={selectedPath}
										isFolder={node.isFolder}
										selectedNode={node.name}
										title={node.name}
									/>
								)}
							</div>
						);
					})}
					{Object.values(uploads).map((upload) => {
						const path = upload.path.split('/');
						path.pop();
						const uploadSelectedPath = path.join('/');

						if (uploadSelectedPath !== selectedPath) return null;

						return (
							<SRSUpload
								key={upload.fileName}
								fileName={upload.fileName}
								progress={upload.progress}
								uploadSpeed={upload.uploadSpeed}
								error={upload.error}
								waiting={upload.waiting}
								pathKey={upload.path}
							/>
						);
					})}
					{isAddFolderOpen ? (
						<div className="SRSFileStructureBody__Add">
							<SRSAdd
								sectionId={sectionId}
								onCancel={() => setIsAddFolderOpen(false)}
								addType="folder"
							/>
						</div>
					) : (
						<div className="SRSFileStructureBody__AddButton">
							<SRSAddFolder
								onClick={() => {
									setIsAddFolderOpen(true);
								}}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
