/**
 * Converts a numerical value of bytes into a human-readable string format,
 * automatically choosing the appropriate unit (Bytes, KB, MB, GB, TB).
 *
 * @param {number} bytes - The file size in bytes.
 * @returns {string} The human-readable file size with appropriate unit.
 *
 * @example
 * // Returns '1 KB'
 * convertBytes(1024);
 *
 * @example
 * // Returns '0 Bytes'
 * convertBytes(0);
 */
export const convertBytes = (bytes: number): string => {
	if (bytes === 0) {
		return '0 Bytes';
	}
	const k = 1024; // 1024 base size
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']; // Units
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

/**
 * Formats the given number of bytes into the most suitable unit of speed.
 *
 * @param {number} bytes - The number of bytes transferred.
 * @param {number} deltaTime - The time in seconds over which the transfer took place.
 * @returns {string} The formatted speed ending with the unit of measure.
 */
export function convertSpeed(bytes: number, deltaTime: number): string {
	const kb: number = 1024;
	const mb: number = kb * 1024;
	const gb: number = mb * 1024;
	let speed: number = bytes / deltaTime; // bytes per second

	if (speed < mb) {
		return (speed / kb).toFixed(2) + ' KB/s';
	} else if (speed < gb) {
		return (speed / mb).toFixed(2) + ' MB/s';
	} else {
		return (speed / gb).toFixed(2) + ' GB/s';
	}
}
