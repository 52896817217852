import './DescriptionTip.scss';

export const DescriptionTip = ({ description }: { description: string }) => {
	return (
		<div className="DescriptionTip">
			<div className="DescriptionTip__Title">Description</div>
			<div className="DescriptionTip__Content">{description}</div>
		</div>
	);
};
