export const upperFirst = (str: string) => {
	if (str.length === 0) {
		return str;
	}

	const [first, ...rest] = str.split('');

	return `${first.toUpperCase()}${rest.join('').toLowerCase()}`;
};

export const formatCamelCase = (text: string) => {
	return text
		.replace('_', ' ')
		.trimStart()
		.trimEnd()
		.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

/**
 * Removes the first occurrence of a specified substring from the given string.
 * If the substring is not found, the original string is returned unchanged.
 *
 * @param {string} str - The string from which to remove the substring.
 * @param {string} substr - The substring to remove.
 * @returns {string} The new string with the first occurrence of the specified substring removed.
 */
export function removeFirstOccurrence(str: string, substr: string): string {
	const index = str.indexOf(substr);
	if (index === -1) {
		return str;
	}
	return str.slice(0, index) + str.slice(index + substr.length);
}

/**
 * Removes the last occurrence of a specified substring from the given string.
 * If the substring is not found, the original string is returned unchanged.
 *
 * @param {string} str - The string from which to remove the substring.
 * @param {string} substr - The substring to remove.
 * @returns {string} The new string with the last occurrence of the specified substring removed.
 */
export function removeLastOccurrence(str: string, substr: string): string {
	const index = str.lastIndexOf(substr);
	if (index === -1) {
		return str;
	}
	return str.slice(0, index) + str.slice(index + substr.length);
}

/**
 * Removes the first character from the given string. If the string is empty, it returns an empty string.
 *
 * @param {string} str - The string from which to remove the first character.
 * @returns {string} The string without its first character.
 */
export function removeFirstCharacter(str: string): string {
	return str.slice(1);
}
