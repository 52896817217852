import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import './ToolTip.scss';

type Props = {
	isEnabled?: boolean;
	children: ReactNode;
	elementRef: React.RefObject<HTMLDivElement>;
	tip: string | ReactNode;
};
export const ToolTip = ({
	children,
	elementRef,
	tip,
	isEnabled = true,
}: Props) => {
	const [hover, setHover] = useState<boolean>(false);
	const [toolTipRef, setToolTipRef] = useState<any>(null);

	const { styles, attributes } = usePopper(elementRef.current, toolTipRef, {
		placement: 'top',
	});

	const enterHandler = useCallback(() => {
		setHover(true);
	}, [setHover]);

	useEffect(() => {
		const currentElement = elementRef.current;

		currentElement?.addEventListener('mouseenter', enterHandler);
		currentElement?.addEventListener('touchstart', enterHandler);

		return () => {
			currentElement?.removeEventListener('mouseenter', enterHandler);
			currentElement?.removeEventListener('touchstart', enterHandler);
		};
	}, [elementRef, enterHandler]);

	const leaveHandler = useCallback(() => {
		setHover(false);
	}, [setHover]);

	useEffect(() => {
		const currentElement = elementRef.current;

		currentElement?.addEventListener('mouseleave', leaveHandler);
		currentElement?.addEventListener('touchend', leaveHandler);

		return () => {
			currentElement?.removeEventListener('mouseleave', leaveHandler);
			currentElement?.removeEventListener('touchend', leaveHandler);
		};
	}, [elementRef, leaveHandler]);

	const popper = isEnabled ? (
		<AnimatePresence>
			{hover && (
				<motion.div
					className="ToolTipWrapper"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{
						opacity: 0,
						transition: { type: 'spring', delay: 0, duration: 0.3, bounce: 0 },
					}}
					transition={{
						type: 'spring',
						delay: 0.3,
						duration: 0.7,
						bounce: 0,
					}}
				>
					<div
						className="ToolTip"
						ref={setToolTipRef}
						style={styles.popper}
						{...attributes.popper}
					>
						{tip}
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	) : null;

	const element = document.querySelector('#popperRoot');

	return (
		<>
			{children}
			{element && createPortal(popper, element)}
		</>
	);
};
