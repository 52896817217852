import { Icon } from '../Icon/Icon';
import './LoadingBlock.scss';

interface LoadingBlockProps {
	iconSize?: number;
	color?: string;
	backgroundColor?: string;
};

/**
 * LoadingBlock Component
 *
 * Displays a loading indicator with customizable icon size, color, and background color.
 * This component is useful for showing a loading state in parts of your application.
 *
 * @param {Props} props The properties for the LoadingBlock component.
 * @param {number} props.iconSize The size of the loading icon. Defaults to 30 pixels if not specified.
 * @param {string} props.color The color of the loading icon. Defaults to white if not specified.
 * @param {string} props.backgroundColor The background color of the loading block. Defaults to transparent if not specified.
 *
 * @returns {JSX.Element} A React component that renders a loading block with an icon.
 *
 * Usage:
 * ```
 * <LoadingBlock
 *   iconSize={40}
 *   color="red"
 *   backgroundColor="black"
 * />
 * ```
 *
 * Note: Ensure that the 'LoadingSpinner' icon is available in your Icon component for the loading indicator to work correctly.
 */
export const LoadingBlock = ({
	iconSize = 30,
	color,
	backgroundColor,
}: LoadingBlockProps) => {
	return (
		<div
			className="LoadingBlock"
			style={{
				color: color ? color : 'white',
				backgroundColor: backgroundColor ? backgroundColor : '',
			}}
		>
			<Icon name="LoadingSpinner" width={iconSize} />
		</div>
	);
};
