import { useState } from 'react';
import './Textbox.scss';

interface TextboxProps {
	value: string;
	title: string;
	multiline?: boolean;
	onChange: (value: string) => void;
}

/**
 * Represents a customizable Textbox component. This component is not intended
 * as a form input, but as an interactive display element for input fields
 * that enhances UI/UX by providing visual feedback on focus and value changes.
 *
 * @component
 * @example
 * <Textbox
 *   value="Hello"
 *   title="Greeting"
 *   multiline={false}
 *   onChange={(newValue) => console.log(newValue)}
 * />
 *
 * @param {string} value - The current value of the textbox.
 * @param {string} title - The label or title displayed above the textbox.
 * @param {boolean} [multiline=false] - Determines if the textbox should allow multiple lines of input.
 * @param {Function} onChange - Callback function that is called when the textbox's value changes.
 *                                    Takes the new value as a parameter.
 */
export const Textbox = ({
	value,
	title,
	multiline = false,
	onChange,
}: TextboxProps) => {
	const [isFocused, setIsFocused] = useState(false);

	/**
	 * Sets the textbox component as focused.
	 */
	const handleFocus = () => setIsFocused(true);

	/**
	 * Removes focus from the textbox component.
	 */
	const handleBlur = () => setIsFocused(false);

	const hasValue = value !== '';

	return (
		<div className={`Textbox ${isFocused || hasValue ? 'is-active' : ''}`}>
			<div
				className={`Textbox__Title ${isFocused || hasValue ? 'is-active' : ''}`}
			>
				{title}
			</div>

			{!multiline ? (
				<input
					type="text"
					className="Textbox__Input"
					value={value}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={(e) => onChange(e.target.value)}
				/>
			) : (
				<textarea
					className="Textbox__TextArea"
					value={value}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={(e) => onChange(e.target.value)}
				/>
			)}
		</div>
	);
};
