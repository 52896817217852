import { useLocation } from 'react-router-dom';
import { isDefined } from '../../../../Core/utils/Logic';
import { useSelectedPath } from '../SRS.state';
import './SRSBodySelector.scss';
import { SRSFileStructureBody } from './SRSFileStructureBody';
import { SRSRevisionBody } from './SRSRevisionBody';

// This component is the main component for the SRS file management. It is responsible for rendering the correct body component based on the selected path.

export const SRSBodySelector = () => {
	// Controls whether the root is at a revision or not for the add folder/revision button - If "" then it is at the root
	const selectedPath = useSelectedPath();
	const location = useLocation();

	const getSectionId = () => {
		// Split the path by '/' and filter out the empty ones that can be left over if we end with a '/'
		const splitPath = location.pathname.split('/').filter((path) => path);

		const [, , moduleName, maybeSectionId] = splitPath;

		if (moduleName === 'explorer' && isDefined(maybeSectionId)) {
			return maybeSectionId;
		}
	};

	const sectionId = getSectionId()!;

	return (
		<div className="SRSBodyFileComponent">
			<div className="SRSBodyFileComponent__Body">
				{selectedPath === '' ? (
					<>
						<SRSRevisionBody
							sectionId={sectionId}
							selectedPath={selectedPath}
						/>
					</>
				) : (
					<SRSFileStructureBody
						sectionId={sectionId}
						selectedPath={selectedPath}
					/>
				)}
			</div>
		</div>
	);
};
