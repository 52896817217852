import { convertBytes } from '../../../../../Core/utils/Convert';
import { formatToLocalDateTime } from '../../../../../Core/utils/Dates';
import { MetaData } from '../../../../../SharedTypes/API/Explorer';

import './FileTreeTip.scss';

export const FileTreeTip = ({ metaData }: { metaData: MetaData }) => {
	return (
		<div className="FileTreeTip">
			<div className="FileTreeTip__Title">File Information</div>
			<div className="FileTreeTip__Content">
				<div className="FileTreeTip__Content__Row">
					<div className="FileTreeTip__Content__Row__Key">Bucket:</div>
					<div className="FileTreeTip__Content__Row__Value">
						{metaData.bucket}
					</div>
				</div>
				<div className="FileTreeTip__Content__Row">
					<div className="FileTreeTip__Content__Row__Key">Size:</div>
					<div className="FileTreeTip__Content__Row__Value">
						{convertBytes(metaData.size)}
					</div>
				</div>
				<div className="FileTreeTip__Content__Row">
					<div className="FileTreeTip__Content__Row__Key">Time Created:</div>
					<div className="FileTreeTip__Content__Row__Value">
						{formatToLocalDateTime(metaData.timeCreated)}
					</div>
				</div>
				<div className="FileTreeTip__Content__Row">
					<div className="FileTreeTip__Content__Row__Key">Last Modified:</div>
					<div className="FileTreeTip__Content__Row__Value">
						{formatToLocalDateTime(metaData.updated)}
					</div>
				</div>
			</div>
		</div>
	);
};
