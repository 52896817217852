import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../Core/redux/store';
import { useAppSelector } from '../../Core/redux/useAppSelector';

const selectExplorer = (state: RootState) => state.explorer;

export const selectElementDimensionConfiguration = createSelector(
	selectExplorer,
	(explorer) => explorer.elementDimensionConfig
);

export const selectElementSortedDimension = createSelector(
	selectExplorer,
	(explorer) => explorer?.elementDimensionConfig?.sortedDimension ?? null
);

export const selectJointSortedDimension = createSelector(
	selectExplorer,
	(explorer) => explorer?.jointDimensionConfig?.sortedDimension ?? null
);

export const selectJointDimensionConfiguration = createSelector(
	selectExplorer,
	(explorer) => explorer.jointDimensionConfig
);

// export const selectChosenSectionId = createSelector(
// 	selectExplorer,
// 	(explorer) => explorer.chosenSectionId
// );

export const selectDimensionChooserState = createSelector(
	selectExplorer,
	(explorer) => explorer.dimensionChooser
);

export const selectAnalysisExplorerPanel = createSelector(
	selectExplorer,
	(explorer) => explorer.analysisExplorerPanel
);

export const selectSelectedComponents = createSelector(
	selectExplorer,
	(explorer) => explorer.selectedComponents
);

export const selectHighlightedComponent = createSelector(
	selectExplorer,
	(explorer) => explorer.highlightedComponent
);

export const selectLastChosenSectionId = createSelector(
	selectExplorer,
	(explorer) => explorer.lastChosenSectionId
);

export const selectZoomConstant = createSelector(
	selectExplorer,
	(explorer) => explorer.zoomConstant
);

export const useLastChosenSectionId = (): string =>
	useAppSelector((state: RootState) => state.explorer.lastChosenSectionId);
