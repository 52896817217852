import { AnimatePresence, motion } from 'framer-motion';
import './SRSEdit.scss';
import { useEffect, useState } from 'react';
import { Textbox } from '../../../../../Core/components/Textbox/Textbox';
import { Button } from '../../../../../Core/components/Button/Button';
import { useDispatch } from 'react-redux';
import { setNodeCurrentlyEdited } from '../../SRS.state';
import {
	useEditSRSNodeMutation,
	useGetSRSFolderDescriptionQuery,
} from '../../../../../Core/Api';
import { Icon } from '../../../../../Core/components/Icon/Icon';
import classNames from 'classnames';

interface SRSEditProps {
	selectedPath: string;
	selectedNode: string;
	sectionId: string;
	isFolder: boolean;
	title: string;
}

export const SRSEdit = ({
	selectedPath,
	selectedNode,
	sectionId,
	isFolder,
	title,
}: SRSEditProps) => {
	// Get the title extension if there is a title extension
	const titleExtension = title.split('.').pop();
	const [titleWithoutExtension] = title.split(`.${titleExtension}`);
	const [titleTextBoxValue, setTitleTextBoxValue] = useState(
		titleWithoutExtension
	);

	const [editNode] = useEditSRSNodeMutation();

	const handleTitleTextBoxValue = (value: string) => {
		//do not allow special symbols other than -
		//GCP Buckets are very picky about what characters are allowed
		value = value.replace(/[^a-zA-Z0-9-]/g, '');

		setTitleTextBoxValue(value);
	};

	let pathToTheDescription = selectedPath.concat(
		'/',
		selectedNode,
		'/',
		selectedNode
	); // Looks stupid, but we need to make sure it looks in the folders, path for it

	// Remove any leading slashes from the path
	pathToTheDescription = pathToTheDescription.replace(/^\/+/, '');

	const { isError, isLoading, data } = useGetSRSFolderDescriptionQuery(
		{
			sectionId,
			selectedPath: pathToTheDescription,
		},
		{ skip: !isFolder }
	);

	const [descriptionTextBoxValue, setDescriptionTextBoxValue] = useState<
		string | undefined
	>(undefined);

	// when the data is loaded, set the descriptionTextBoxValue to the description
	useEffect(() => {
		if (!isError && !isLoading && data) {
			setDescriptionTextBoxValue(data.description);
		}
	}, [data, isError, isLoading]);

	const handleDescriptionTextBoxValue = (value: string) => {
		//max length of 200 characters
		if (value.length <= 200) {
			setDescriptionTextBoxValue(value);
		}
	};

	const dispatch = useDispatch();

	const closeEdit = () => {
		dispatch(setNodeCurrentlyEdited(''));
	};

	const handleSaveEdit = () => {
		if (
			(!titleTextBoxValue || titleTextBoxValue === titleWithoutExtension) &&
			!descriptionTextBoxValue
		) {
			closeEdit();
			return;
		}

		const isSRSFolderPositionAtRoot = selectedPath === '';
		editNode({
			sectionId,
			nodePath: !isSRSFolderPositionAtRoot
				? isFolder
					? selectedPath.concat('/', selectedNode, '/') // If folder add trailing slash - GCP Buckets use trailing slashes for folders
					: selectedPath.concat('/', selectedNode) // If file don't add trailing slash)
				: selectedNode.concat('/'),
			newNodeName:
				titleTextBoxValue !== title
					? titleTextBoxValue.concat(
							titleExtension && title !== titleExtension
								? `.${titleExtension}`
								: ''
					  )
					: undefined,
			newNodeDescription: descriptionTextBoxValue,
		}).then(() => {
			closeEdit();
		});
	};

	const [isLoadingChange, setIsLoadingChange] = useState(false);

	return (
		<AnimatePresence>
			<div className="SRSEdit">
				<motion.div
					initial={{ opacity: 0, y: -50 }} // Starting state before the animation
					animate={{ opacity: 1, y: 0 }} // End state after the animation
					transition={{ duration: 0.2 }} // Duration of the animation
				>
					<div className="SRSEdit__TextboxContainer">
						{/* {!isFolder && ( */}
						<Textbox
							title={'Title'}
							onChange={handleTitleTextBoxValue}
							value={titleTextBoxValue}
						/>
						{/* )} */}

						{isFolder && (
							<Textbox
								title={'Description'}
								onChange={handleDescriptionTextBoxValue}
								value={descriptionTextBoxValue || ''}
								multiline
							/>
						)}
					</div>
					<div
						className={classNames('SRSEdit__Buttons', {
							'SRSEdit__Buttons--Loading': isLoadingChange,
						})}
					>
						<Button theme="OutlineBlue" onClick={closeEdit}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								handleSaveEdit();
								setIsLoadingChange(true);
							}}
						>
							{isLoadingChange ? (
								<Icon
									name="LoadingSpinner"
									width={15}
									style={{ color: 'Blue' }}
								/>
							) : (
								'Save'
							)}
						</Button>
					</div>
				</motion.div>
			</div>
		</AnimatePresence>
	);
};
