import { IconButton } from '../../../../../Core/components/IconButton/IconButton';

interface SRSAddFolderProps {
	onClick: () => void;
}

export const SRSAddFolder = ({ onClick }: SRSAddFolderProps) => {
	return (
		<IconButton
			icon="CirclePlus"
			alignText="left"
			theme="blue-text-transparent"
			iconSize={18}
			onClick={onClick}
		>
			Add folder
		</IconButton>
	);
};
